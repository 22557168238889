
.readMoreSection {
    width: 90%;
    margin: 40px auto;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
    box-shadow: var(--box-shadow-hover);
    transition: all 0.3s ease;
    transform: scale(1);
    cursor: pointer;
}
.readMoreContent {
    display: flex;
    width: 100%;
}
.readMoreSection:hover {
    transform: scale(1.02);
    box-shadow: var(--box-shadow);
}

.readMoreHeading {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 10px;
}

.readMoreHeading>div {
    color: green;
    font-size: 24px;
    padding: 0 4px 0 4px;
}

.line {
    background-color: green;
    flex: 1;
    height: 4px;
    padding: 0 10px 0 10px;
    box-sizing: border-box;
}

.imgContainer {
    display: flex;
    width: 100%;
    gap: 8px;
    max-height: 350px;
}

.imgContainer > img {
    width: 60%;
    object-fit: cover;
    box-shadow: var(--box-shadow);
    border-radius: 16px;
    transition: all 0.3s ease;


}

.vertImages {
    width: 30%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.vertImages>img {
    width: 100%;
    box-shadow: var(--box-shadow);
    border-radius: 16px;
    transition: all 0.3s ease;
}

.vertImages>img:hover,
.imgContainer>img:hover {
    transform: scale(1.1);
}

.readMoreText {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.readmoreLink {
    color: green;
    text-decoration: none;
    cursor: pointer;
}
@media screen and (max-width: 960px) {
    * {
        font-size: 16px
    }

    .line {
        height: 2px;
    }

    .readMoreHeading>div {
        font-size: 16px;

    }

    .imgContainer {
        width: 100%;
        flex-direction: column;
        max-height: unset;
    }
    .readMoreContent {
        flex-direction: column;
    }
    .imgContainer > img {
        width: 100%;
    }
    .vertImages {
        flex-direction: row;
        width: 100%;

    }
    .vertImages > img {
        width: 50%;
    }
    .readMoreText {
        padding: 10px;
        width: 100%;
    }
    .readMoreSection {
        width: 95%;
    }
}