.logoContainer {
    width: 20%;

}
.logo {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-filter: drop-shadow(4px 4px 4px #000000);
    filter: drop-shadow(4px 4px 4px #000000); 
    transition: all 0.3s ease-out;
}
.logo:hover {
    -webkit-filter: drop-shadow(2px 2px 2px  #000000);
    filter: drop-shadow(2px 2px 2px  #000000); 

}
@media only screen and (max-width: 960px) {
    .logoContainer {
        padding: 10px;
        width: 30%;
        max-width: 350px;
  

    }
}