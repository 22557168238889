.certificationsWrapper {
    width: 100%;
    margin:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.certificateGroup {
    width: 90%;
    justify-content: center;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}