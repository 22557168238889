.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.siteWrapper {
  width: 100%;
  overflow: hidden;
}
.wrapper {
  width: 100%;
  max-width: 1320px;
  margin: auto;
  min-height: 500px;

}
@media only screen and (max-width: 960px ) {
  .wrapper {
    width: 95%;
    margin-top: 120px;

  }
}
