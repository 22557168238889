.navBarContainer {
    width: 100%;
    display: flex;
    top: 0 !important;
    left: 0;
    justify-content: space-around;
    align-items: center;
    animation: navBarEaseIn 0.6s ease-in;
    z-index: 10;
    box-shadow: var(--box-shadow);
}

.navBarContainer > .navigationOptions > div {
    width: 150px;
}

.navIcons {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 20px 0 0;
}
.navigationOptions {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.mobileNav {
    position: absolute;
    right: 0;
    top: 0;
}

.crossIcon {
    position: absolute;
    width: min-content;
    top: -5px;
    right:20px;
    padding: 8px 5px;
}
.crossIcon > svg {
    width: 30px;
    height: 30px;
}
.navSeprator{
    margin-top: 10px;
    height: 2px;
    border-radius: 8px;
}

@media only screen and (max-width: 960px) {
    .navBarContainer {
        position: fixed !important;
        top: 0 !important;
        left: 0;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        box-shadow: var(--box-shadow) !important;
        
    }
    .navigationOptions {
        width: 225px;
        flex-direction: column;
        height: 50vh;
        
    }
    .mobileNavlist {
        padding: 16px;
        box-sizing: border-box;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        background-color: rgba(253, 253, 253, 0.74);
        transform:  translateX(200px);
        transition: all 0.3s ease-out;
        box-shadow: var(--box-shadow) !important;
        z-index: 3;
        border-radius: 8px;
    }
    .navSeprator{
        margin-top: 0px;
        height: 2px;
        border-radius: 8px;
    }
    .mobileNavlist.openNavBar{
        top: 0;
        pointer-events: stroke;
        opacity: 1;
        transform:  translateX(20px);
    }
    @keyframes navBarEaseIn {
        0%{
            opacity: 0;
            transform: translateY(-10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
