.packageWrapper{
    width: 100%;
    height: 100%;
    position: relative;
    transform: scale(1);
    transition: all 0.3s ease-in;   
}
.packageWrapper::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(closest-side, #24f50862, #6deb0c31, #ffffff00);
    transition: all 0.3s ease-in;
}
.packageWrapper:hover::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(closest-side, #24f50862, #6deb0c88, #ffffff00);
}
.packageWrapper:first-child {
    width: 100%;  
}
.packageWrapper img {
    width: 50%;
    filter: drop-shadow(4px 4px 4px #000000); 
    transition: all 0.3s ease-out;
}
.packageWrapper:hover {
    transform: scale(1.05);
}
.firstImage {
    transform: translateX(80%) rotateZ(20deg) scale(0.8);
    animation: firstPackageMove 4s infinite;
}
.secondImage {
    transform: translateX(-70%) rotateZ(-20deg) scale(0.8);
    animation: secondPackageMove 4s infinite;
}

.secondImage:hover {
    transform: translateX(-70%) rotateZ(-20deg) scale(0.9);
}
@keyframes firstPackageMove {
    0%,100%{
        transform: translateX(80%) rotateZ(20deg) scale(0.8);
    }
    50% {
        transform: translateX(82%) rotateZ(24deg) scale(0.8);
    }
}
@keyframes secondPackageMove {
    0%,100%{
        transform: translateX(-70%) rotateZ(-16deg) scale(0.8);
    }
    50% {
        transform: translateX(-70%) rotateZ(-20deg) scale(0.8);
    }
}