.aboutUsWrapper {
    width: 100%;
    margin:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.aboutContent {
    padding-top: 20px;
    width: 80%;
}
.aboutContent p {
    text-indent: 75px;
    font-size: 22px;
    padding-top: 10px;
}
p[id="intro"]::first-letter, .headings{
    color: green;
    font-weight: 700;
    font-size: 25px;
}
.headings {
    padding-top: 20px;
    text-decoration: underline;
    text-align: center;
}
@media screen and (max-width:960px) {
    .aboutContent p {
        font-size: 16px;
    }
}