.productWrapper {
    width: 100%;
    margin:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.productImage {
    width: 80%;
    object-fit: cover;
    max-width: 200px;
    filter: drop-shadow(2px 2px 2px  #000000); 
}
.productName {
    color: green;
    font-weight: 700;
}

.introduction p {
    text-indent: 75px;
    font-size: 22px;
    padding-top: 10px;
}

.productContent {
    width: 80%;
    padding-top: 20px;
}
.packageImage {
    width: 100%;
    height: 100%;
}

.productDescriptionSection {
    display: flex;
    margin: auto;

}
.productDescriptionSection > div {
    width: 50%;
    display: flex;
    justify-content: center;
}
.productDescription {
    width: 100%;
}
.productDescription table th {
    width: 30%;
}
.productDescription table td {
    font-style: italic;
}
.productDescription table {
    padding: 10px;
    border: 4px solid green;
    border-radius: 8px;

}
.textSection {
    padding-top: 10px;
    font-size: 22px;
}
h2.heading, .subHeading {
    color: green;
    text-decoration: underline;
}

@media screen and (max-width:960px) {
    .introduction p {
        font-size: 16px;
    }
    .productDescriptionSection {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
    }
    .productDescriptionSection > div {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
    }
    .productDescription table th, .productDescription table td {
        width: 50%;
        padding: 5px;
    }
}