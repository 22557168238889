.homeContainer {
    background-color: var(--button-green);
}

.textContent {
    width: 80%;
    margin: auto;
    font-size: 22px;
}

.textContent p {
    text-indent: 100px;
}

.whyUS {
    color: green;
    font-size: 20px;
}

.bulletPoints .bulletIcon {
    color: green;
    width: 20px;
    height: 20px;
    padding-top: 3px;
    ;
}

.bulletPoints .statement {
    color: black;
}

.map {
    display: flex;
    width: 100%;
    height: 500px;
    object-fit: cover;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.bulletPoints {
    padding-top: 10px;
    display: flex;
    width: 100%;
    gap: 10px;
}



@media screen and (max-width: 960px) {
    * {
        font-size: 16px
    }

    .line {
        height: 2px;
    }

    .readMoreHeading>div {
        font-size: 16px;

    }

    .textContent {
        width: 80%;
        font-size: 16px;
    }

    .textContent p {
        text-indent: 10px;
    }

    .bulletPoints {
        padding-top: 10px;
        gap: 10px;
    }

    .map {
        width: 100%;
    }

    .imgContainer {
        width: 100%;
        flex-direction: column;
    }
    .readMoreContent {
        flex-direction: column;
    }
    .imgContainer > img {
        width: 100%;
    }
    .vertImages {
        flex-direction: row;
        width: 100%;

    }
    .vertImages > img {
        width: 50%;
    }
    .readMoreText {
        padding: 10px;
        width: 100%;
    }
    .readMoreSection {
        width: 95%;
    }
}