.fixedLogoWrapper{
    z-index: 12;
    position: fixed;
   
    

}
.fixedImage {
    width: 150px;
    object-fit: cover;
    animation: fixedImageMove 3s ease-in infinite;
    filter: drop-shadow(2px 2px 2px #000000); 
}
@keyframes fixedImageMove {
    0%{
        transform: rotateZ(0);
    }
    25%{
        transform: rotateZ(2deg);
    }
    50% {
        transform: rotateZ(0);
    }
    75% {
        transform: rotateZ(-2deg);
    }
    100%{
        transform: rotateZ(0);
    }
}
@media only screen and (max-width: 960px) {

.fixedImage {
    width: 80px;
}
}