
.image {
    border-radius: 16px;
    filter: drop-shadow(0 0 0.2rem rgba(9, 71, 3, 0.5));
    user-select: none;
    cursor: pointer;
    padding: 10px 0 0 0;
    transition: all 0.3s ease-out;
    transform: scale(1);
}
.image:hover {
    filter: drop-shadow(0 0 0.2rem rgba(9, 71, 3, 0.801));
    transform: scale(1.03);
    z-index: 20;
}