.imageFrameWrapper {
    width: 100%;
    gap: 2px;
    display: flex;
    box-shadow: var(--box-shadow);
    transition: all 0.3s ease-out;
    background-color: white;
}
.imageFrameWrapper:hover {
    transform: scale(1.1);
}
.frameImageWrapper {
    overflow: hidden;
    object-fit: cover;
    width: calc(100% - 8px);
}
.frameImage {
    width: 100%;
    object-fit: cover;
}

.frameMargin {
    width: 4px;
    background-color: green;
    border-radius: 4px;
}