.wrapper {
	position: relative;
	display: flex;
}

.wrapper.horizontal {
	width: 100%;
	flex-direction: column;
	height: min-content;
}

.wrapper.vertical {
	flex-direction: row;
	width: min-content;
}

.carouselWrapper {
	position: relative;
	min-height: 80px;
	min-width: 100px;
	user-select: none;
}

.carouselWrapper.horizontal {
	width: 100%;
	overflow: hidden;
	padding: 5px 0 5px 0;
}

.carouselWrapper.vertical {
	overflow: hidden;
	height: 100%;
}

.carouselItems {
	box-sizing: border-box;
	display: flex;
	gap: 16px;
	position: absolute;
	scroll-behavior: smooth;
	transition: transform 300ms ease-out;
	user-select: none;
	align-items: center;
	justify-content: center;

}

.carouselItems.horizontal {
	flex-direction: row;
	padding: 0 10px 0 10px;
	animation: infiniteScroll 90s ease-out infinite;
}

.carouselItems.vertical {
	padding: 1px;
	flex-direction: column;
}

.leftButton,
.rightButton,
.upButton,
.downButton {
	position: absolute;
	/* cursor: pointer; */
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none
}

.leftButton,
.rightButton {
	top: 0;
	bottom: 0;
	width: calc(max(10%, 40px));
}

.upButton,
.downButton {
	left: 0;
	right: 0;
	height: calc(max(10%, 40px));
}

.leftButton {
	display: none;
	background: linear-gradient(to left, transparent 0%, white 50%);
	left: 0;
	z-index: 1;
}

.rightButton {
	display: none;;
	right: 0;
	background: linear-gradient(to right, transparent 0%, white 50%);
}

.upButton {
	background: linear-gradient(to top, transparent 0%, white 50%);
	top: 0;
	z-index: 1;
}

.downButton {
	background: linear-gradient(to bottom, transparent 0%, white 50%);
	bottom: 0;
	z-index: 1;
}

.arrowIconsRight,
.arrowIconsLeft,
.arrowIconsDown,
.arrowIconsUp {
	width: 20px;
	height: 20px;
}

.arrowIconsRight {
	padding: 0 0 0 20px;
}

.arrowIconsLeft {
	padding: 0 20px 0 0;
}

.scrollWrapper {
	position: relative;
	scroll-behavior: smooth;
	padding-left: 4px;
}

.scrollWrapper.horizontal {
	overflow-x: scroll;
}

.scrollWrapper.vertical {
	overflow-y: scroll;
}

.scrollBarDiv.horizontal {
	height: 0.1px;
}

.scrollVarDiv.vertical {
	width: 0.1px;
}

.scrollWrapper.horizontal::-webkit-scrollbar {
	background-color: transparent;
	height: 0px;
	border-radius: 4px;
}

.scrollWrapper.horizontal::-webkit-scrollbar-thumb {
	background-color: #2aff00;
	border-radius: 4px;
}

.scrollWrapper.vertical::-webkit-scrollbar {
	background-color: #DEDEDE;
	width: 8px;
	border-radius: 4px;
}

.scrollWrapper.vertical::-webkit-scrollbar-thumb {
	background-color: #767676;
	border-radius: 4px;
}

@keyframes infiniteScroll {

	0%{
		transform: translateX(0);
	}

	50% {
		transform: translateX(-50%);
	}

	100% {
		transform: translateX(0);
	}

}