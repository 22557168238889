.wrapper {
    margin-top: 100px;
}

.footerContainer {
    width: 100%;
    max-width: 1320px;
    min-height: 200px;
    margin: auto;
    background: url("../../assets/images/bottom.svg") no-repeat;
    background-size: cover;
    background-position: 0 -300px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: end;
}

hr {
    width: 90%;
    margin: auto;
    height: 1px;
    background-color: rgba(201, 97, 29, 0.514);
}

.footerContent,
.information {
    width: 88%;
    display: flex;
    justify-content: center;
    align-items: end;
}

.address,
.contactInfo {
    width: 50%;
}

.address {
    display: flex;
    height: 75px;
    justify-content: space-between;
    flex-direction: column;
}

.contactInfo>div {
    padding: 20px 0 0 0;
}

.icons {
    width: 20px;
    height: 20px;
    padding: 2px;
}

.info {
    display: flex;
    justify-content: start;
    align-items: center;
}

.info a {
    text-decoration: none;
    color: black;
}

.emails a {
    text-decoration: underline;
}

.emails {
    display: flex;
    justify-content:center;
    gap:8px;
    align-items:center;
}
.email {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 960px) {

    .footerContent,
    .information {
        width: 90%;
        display: flex;
        flex-direction: column;
    }


    .address,
    .contactInfo {
        width: 100%;
        box-sizing: border-box;
    }

    .address {
        height: 75px;
    }

    .footerContainer {
        background-position: 0 0;
        align-items: center;
    }
    .emails {
        flex-direction: column-reverse;
        align-items: start;
    }
}