.headerContainer {
    box-sizing: border-box;
    width: 100%;
    padding: 30px 30px 10px 30px;
    background: url('../../assets/images/bg55.svg') no-repeat ; 
    background-size: cover;
    background-position: 0 -420px ;
    z-index: 100;
}

@media only screen and (min-width: 960px) and (max-width: 1024px) {
    .headerContainer {
        background-position: 0px -50px;
        padding: 10px;
        position: fixed;
        top: 0;
        backdrop-filter: blur(8px);

    }
}
@media only screen and (max-width: 960px) {
    .headerContainer {
        background-position: 0px -90px;
        padding: 10px;
        height: 100px;
        position: fixed;
        top: 0px;
        backdrop-filter: blur(8px);

    }

}