.buttonContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;    
    border: 1px solid transparent;
    background: transparent;
    transition: all 0.3s ease-out;
    box-shadow: none;
    text-decoration: none;
    padding: 5px;
}
a{
    text-decoration: none;
}
.buttonContainer.small {
    height: var(--button-small);
}
.buttonContainer.medium {
    height: var(--button-medium);
}
.buttonContainer.large {
    height: var(--button-large);
}
.button {
    appearance: none;
    border: 0;
    background-color: transparent;
    text-decoration: none;
}

.buttonContainer:hover, .buttonContainer.isHovered, .buttonContainer:focus {
    border: 1px solid var(--green);
    outline: none;
    box-shadow: var(--box-shadow);
    cursor: pointer;
}
.button:hover {
    cursor: pointer;
}


.icon {
    transform: rotate(0);
    transition: all 0.3s ease-out;
}
.icon.inverted {
    transform: rotate(180deg); 
}

.icon svg {
    z-index: 0;
}
.buttonContainer.isActivated {
    border: 1px solid black;
    outline: none;
    box-shadow: var(--box-shadow);
    cursor: pointer;
}
@media only screen and (max-width: 960px) {
    .buttonContainer {
        justify-content: space-between;
        
    }
}