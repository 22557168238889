
@font-face {
    font-family: "AsapCondensedRegular";
    src: url("../assets/fonts/AsapCondensed-Regular.ttf");
}
:root {
    --button-small: 34px;
    --button-medium: 42px;
    --button-large: 45px; 
    --button-green: unset;
    --bg-green: unset;
    --jerra-packet-color: #3E409B;
    --isabgol-packet-color: #674623;
    --lower-packet-color: #C4A46B;
    --box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.5);
    --box-shadow-hover: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);

    /* @palette */
    --green: #2aff00;

}