.menuListContainer {
    width: 100%;
    z-index: 10;
}

.menuListContainer.small {
    height: var(--button-small);
}

.menuListContainer.medium {
    height: var(--button-medium);
}

.menuListContainer.large {
    height: var(--button-large);
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
    transition: all 0.3s ease-out;
    box-shadow: var(--box-shadow);
    background-color: rgba(6, 255, 6, 0.521);
    backdrop-filter: blur(8px);
}

ul.down {
    transform: scale(0) translateY(-100px);
}

ul.right,
ul.left {
    transform: scale(0) translateX(-100px);
}

.menuListContainer:hover>ul.down {
    transform: scale(1) translateY(0);
}

.menuListContainer:hover>ul.left {
    transform: scale(1) translateX(-100%);
}

.menuListContainer:hover>ul.right {
    transform: scale(1) translateX(100%);
}

.menuListContainer:hover>.heading {
    box-shadow: var(--box-shadow);
}

@media screen and (max-width:960px) {
    ul {
        background-color: rgba(6, 255, 6, 0.788);
    }
}