.bulletPoints .bulletIcon {
    color: green;
    width: 20px;
    height: 20px;
    padding-top: 3px;;
}
.bulletPoints{
    padding: 5px;
    display: flex;
    gap: 10px;
}
.statement {
    color: black;
}